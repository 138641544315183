export default defineNuxtRouteMiddleware((to, _) => {
  const tSlug = to.params.tSlug

  if (!tSlug || Array.isArray(tSlug) || !redirectJson[tSlug]) return

  let navigateToStr = to.path.replace(
    /(tc-)([a-z]+)/g,
    `$1${redirectJson[tSlug][0]}`
  )
  navigateToStr = navigateToStr.replace(
    /(t-)([a-z]+)/g,
    `$1${redirectJson[tSlug][1]}`
  )

  return navigateTo(
    {
      path: navigateToStr,
      query: to.query,
    },
    {
      redirectCode: 301,
    }
  )
})

const redirectJson: { [key: string]: string[] } = {
  memotokeisei: ['eye', 'futae'],
  estheticdentistry: ['dentist', 'orthodontics'],
  nosebotox: ['nose', 'wingnose'],
  ducklipformation: ['lip', 'lipaugmentation'],
  hipsliming: ['hip', 'hipshibouyoukai'],
  medicaldiet: ['body', 'slimming'],
  facialsagging: ['laser', 'hifu'],
  frecklesspots: ['laser', 'ipl'],
  nose: ['nose', 'rhinoplasty'],
  noseprosthesis: ['nose', 'rhinoplasty'],
  facesliminjections: ['face', 'fatsolventinjectionfacecontour'],
  facesliming: ['face', 'fatsolventinjectionfacecontour'],
  jawcontour: ['face', 'jawbtx'],
  ears: ['ear', 'protrudingearsrepair'],
  liphyalu: ['lip', 'liphyaluronicacidinjection'],
  liverspot: ['skin', 'lasertoning'],
  facialredness: ['skin', 'vbeam'],
  acnescarcrater: ['skin', 'potenza'],
  pore: ['skin', 'dermapen4'],
  shiwahyalu: ['injection', 'hyaluronic'],
  wrinklesbtx: ['skin', 'skinbotox'],
  artmake: ['artmake', 'eyebrowartmakeup'],
  regenerate: ['internalmedicine', 'exosomeinjection'],
  selfpaydentalcare: ['dentist', 'whitening'],
  aga: ['aga', 'oralmedicationforagaandfaga'],
  doctorscosme: ['cosmetic', 'lotion'],
  foreheadround: ['forehead', 'foreheadhyaluronicacidinjection'],
}
